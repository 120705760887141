import React, { Component } from 'react';
//import Headroom from 'react-headroom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Row, css, styled, withTheme, Col, up } from '@smooth-ui/core-sc';
import HeaderLogo from 'theme/NavHeader@2x.jpg';

const CustomHeadroom = styled.div`
  .logo-background {
    background: linear-gradient(90deg, #fff 0%, #fff 30%, #034ea2 30%);
  }

  .blue-background {
    background: #034ea2;
  }

  background: linear-gradient(90deg, #fff 0%, #fff 25%, #034ea2 25%);

  ${up(
    'md',
    css`
      background: linear-gradient(90deg, #fff 0%, #fff 15%, #034ea2 15%);
    `
  )}

  ${up(
    'xl',
    css`
      background: linear-gradient(90deg, #fff 0%, #fff 30%, #034ea2 30%);
    `
  )}
`;

const HeaderWrapper = styled.div`
  border-top: 15px solid #034ea2;
  padding: 0;
  width: 100%;
`;

const LogoContainer = styled.div`
  align-items: bottom;
  display: flex;
  height: 94px;
  justify-content: left;
  max-width: 100%;
  width: 100%;

  ${up(
    'lg',
    css`
      padding-left: 10px;
    `
  )}

  img {
    height: 94px;
    width: 269px;
  }
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 5px;
  width: 100%;

  ${up(
    'lg',
    css`
      flex-direction: column;
      padding-top: 2rem;
    `
  )}
`;

class Header extends Component {
  render() {
    return (
      <header className="site-header">
        {/* Leave the above <header /> here, necessary to contain Headroom */}
        {/* Otherwise Headroom will wrap all sibling elements */}
        <CustomHeadroom disableInlineStyles downTolerance={10}>
          <HeaderWrapper className="header-wrapper">
            <Grid>
              <Row justifyContent="center">
                <Col xs={9} lg={3} className="logo-background">
                  <LogoContainer>
                    <Link to="/">
                      <img src={HeaderLogo} alt="Bridgestone Rewards" />
                    </Link>
                  </LogoContainer>
                </Col>
                <Col lg={9} className="blue-background">
                  <HeaderRightContainer>
                    {this.props.children}
                  </HeaderRightContainer>
                </Col>
              </Row>
            </Grid>
          </HeaderWrapper>
        </CustomHeadroom>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

Header.defaultProps = {
  children: undefined,
  theme: undefined,
};

export default withTheme(Header);
